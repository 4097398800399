import React, { useEffect, useState, useContext } from 'react';
import { connect } from "react-redux";
import ImageS3 from "../../../../ImageS3";
import { StatusEnum } from "../../../../../utils/enumsUtil";
import {
  formatDateWithCustomFormat,
  FormatsDate,
} from "../../../../../utils/formatDate";
import { isNullOrUndefined } from '../../../../../utils/functionsUtils';
import { getStatusBadgeByCode, getStatusDataByCode } from "../../../../../utils/StatusUtils";
import { PermissionContext } from '../../../../../hooks/PermissionContext';

const Timeline = ({ numOrder, reduxGetDSPOrderTracking, ordersEvents }) => {
  const [timeline, setTimeline] = useState([]);
  const { hasPermission } = useContext(PermissionContext);
  const getDetailEvent = (ele) => {
    switch (ele.status.code) {
      case StatusEnum.NOENTR:
      case StatusEnum.CREADO:
      case StatusEnum.ENTRAN:
      case StatusEnum.ALISTA:
      case StatusEnum.ENESPE:
      case StatusEnum.ENTREG:
      case StatusEnum.DESPAC:
      case StatusEnum.CENRET:
      case StatusEnum.CENDIS:
      case StatusEnum.ANULAD:
      case StatusEnum.ASIGNA:
      case StatusEnum.REPROG:
    //  case StatusEnum.INVEST:
        return renderFullDetail(ele);
      default:
        return null;
    }
  };

  const renderFullDetail = (ele) => {
    
    const user = ele.user || {};
    const hasValidUser = user.firstName && user.firstName !== '' && user.idUser;

    return (
      <>
        <h2 className="mb-1">
          <a href="#">
          {hasValidUser && (hasPermission('feature:isAdmin') || hasPermission('feature:isAdminDSP')) && `${user.firstName} ${user.lastName}`}
          </a>{" "}
          <span>Cambio estado a: {getStatusBadgeByCode(ele.status.code)}</span>
        </h2>
        {hasValidUser && (hasPermission('feature:isAdmin') || hasPermission('feature:isAdminDSP')) && (
          <div>
            <span className="text-muted">Operador :</span>{" "}
            {user.firstName} {user.lastName}
          </div>
        )}
        {ele.novelty?.description && (
          <div>
            <span className="text-muted">Novedad :</span>{" "}
            {ele.novelty.description}
          </div>
        )}
        {ele.txtNote && (
          <div>
            <span>{"Comentario"} : </span> {ele.txtNote}
          </div>
        )}
        {!isNullOrUndefined(ele.picture) && (
          <div className="row mt-2">
            <div className="col-lg-12">
              <span>Fotos evidencia: </span>
              <div className="d-flex">
                <div className="mt-2">
                  <ImageS3
                    picture={`${ele.picture}`}
                    alt=""
                    className="img-thumbnail"
                  />
                </div>
                {!isNullOrUndefined(ele.picture_2) && (
                  <div className="mt-2">
                    <ImageS3
                      picture={`${ele.picture_2}`}
                      alt=""
                      className="img-thumbnail"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        {ele.signature && (
          <div className="row clearfix mt-2">
            <div className="col-lg-12">
              <span>Firma: </span>
              <div className="map m-t-10 mt-2">
                <ImageS3
                  picture={`${ele.signature}`}
                  alt=""
                  className="img-fluid img-thumbnail m-t-30"
                />
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  useEffect(() => {
    if (numOrder && !isNullOrUndefined(numOrder)) {
      reduxGetDSPOrderTracking(numOrder);
    }
  }, [numOrder, reduxGetDSPOrderTracking]);

  useEffect(() => {
    if (ordersEvents && Object.keys(ordersEvents).length > 0) {
      const sortedTimeline = [...ordersEvents].sort((a, b) => b.idOrderRecord - a.idOrderRecord);
      setTimeline(sortedTimeline);
    }
  }, [ordersEvents]);

  return (
    <div className="container">
      <div className="card shadow mb-4">
        <div className="row mt-4">
          <div className="col-md-10">
            <ul className="cbp_tmtimeline">
              {timeline.map((ele, key) => (
                <li key={key}>
                  <time className="cbp_tmtime" dateTime="2017-10-22T12:13">
                    <span>
                      {formatDateWithCustomFormat(ele.date, {
                        format: FormatsDate.TIME_HOURS,
                      })}
                    </span>
                    <span>
                      {formatDateWithCustomFormat(ele.date, {
                        format: "DD-MM-YYYY",
                      })}
                    </span>
                  </time>
                  <div className={`cbp_tmicon bg-${getStatusDataByCode(ele.status.code).class.bg}`}>
                    <i className={`${getStatusDataByCode(ele.status.code).class.icon}`}></i>
                  </div>
                  <div className="cbp_tmlabel">{getDetailEvent(ele)}</div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  ordersEvents: state.dspState.ordersEvents,
});

const mapDispatchToProps = (dispatch) => ({
  reduxGetDSPOrderTracking: (payload) =>
    dispatch({
      type: 'READ_DSPORDER_TRACKING_REQUEST',
      value: payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Timeline);