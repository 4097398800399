import React, { forwardRef } from "react";

const GuideScanner = forwardRef(({ onScan, ...props }, ref) => {

    const COORD_REGEX = /7(\d{8,})001/;
    
    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            let value = event.target.value.toUpperCase();
            const coordMatch = value.match(COORD_REGEX);

            if(coordMatch) value = coordMatch[1];
            
            onScan(value);
            event.target.value = "";
        }
    };

    return (
        <>
            <input
                {...props}
                type='text'
                className='form-control'
                placeholder='Escanee la Guía'
                onKeyDown={handleKeyDown}
                ref={ref}
            />
        </>
    )
});

export default GuideScanner;