import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Show } from "../../../hooks/Show";
import { logout } from "../../../middlewares/auth";
import { useHistory } from "react-router";
import { useAuth } from "../../../hooks/useAuth";
import { screen } from '@testing-library/react';
import logo from '../../../assets/img/logorgb_kiki.svg';

const Sidebar = () => {
  const history = useHistory();
  const { session, signOut } = useAuth();
  
  const onLogout = () => {
    logout();
    signOut();
    history.push("/");
  };
  
  
    return (
      <ul
        className={"navbar-nav bg-default sidebar sidebar-dark accordion"}
        id="accordionSidebar"
      >
        <a
          className="sidebar-brand d-flex align-items-center justify-content-center"
          href="/welcome"
        >
          <img
            className="img-responsive sidebar-brand-image"
            src={logo}
          />
        </a> 
        <Show when="feature:menu-routes">
          <hr className="sidebar-divider" />
          <div className="sidebar-heading">Administración</div>
          <li className="nav-item">
            <a
              className="nav-link collapsed"
              href="#"
              data-bs-toggle="collapse"
              data-bs-target="#collapse"
              aria-controls="collapse"
            >
              <i className="fas fa-fw fa-map-marked-alt"></i>
              <span>Rutas</span>
            </a>
            <div
              id="collapse"
              className="collapse"
              aria-labelledby="collapse"
              data-parent="#accordionSidebar"
            >
              <div className="bg-white py-2 collapse-inner rounded">
                <Show when="feature:see-routes">
                  <Link className="collapse-item" to="/routing">
                    Rutas en tiempo real
                  </Link>
                </Show>
                <Show when="feature:read-address">
                  <Link className="collapse-item" to="/addresses">
                    Direcciones
                  </Link>
                </Show>
                <Show when="feature:assign-operator">
                  <Link className="collapse-item" to="/assignoperator">
                    Asignar operador
                  </Link>
                </Show>
                <Show when="feature:assign-addresses">
                  <Link className="collapse-item" to="/assignaddress">
                    Asignar direcciones
                  </Link>
                </Show>
                <Show when="feature:assignments-addresses">
                  <Link className="collapse-item" to="/assignments">
                    Asignaciones
                  </Link>
                </Show>
                <Show when="feature:generate-addresses">
                  <Link className="collapse-item" to="/generate">
                    Generador de direcciones
                  </Link>
                </Show>
                <Show when="feature:validate-addresses">
                  <Link className="collapse-item" to="/validate">
                    Validador de direcciones
                  </Link>
                </Show>
                <Show when="feature:generate-pdf-tracking">
                  <Link className="collapse-item" to="/pdf-tracking">
                    Guias
                  </Link>
                </Show>
                <Show when="feature:show-closeConfirmation">
                  <Link
                    className="collapse-item"
                    to="/addressCloseConfirmation"
                  >
                    Confirmación de cierre
                  </Link>
                </Show>
                <Show when="feature:show-rescheduling">
                  <Link className="collapse-item" to="/rescheduling">
                    Reprogramación
                  </Link>
                </Show>
                <Show when="feature:read-orders-locked">
                  <Link className="collapse-item" to="/ordersLocked">
                    Órdenes Bloqueadas
                  </Link>
                </Show>
                <Show when="feature:update-states">
                  <Link className="collapse-item" to="/statesUpdate">
                    Actualización de estados
                  </Link>
                </Show>
              </div>
            </div>
          </li>
          </Show>
          <Show when="feature:wms-menu-show">
            <li className="nav-item">
              <a
                className="nav-link collapsed"
                href="#"
                data-bs-toggle="collapse"
                data-bs-target="#collapse_wms"
                aria-controls="collapse_wms"
              >
                <i className="fas fa-boxes"></i>
                <span>WMS</span>
              </a>
              <div
                id="collapse_wms"
                className="collapse"
                aria-labelledby="collapse_wms"
                data-parent="#accordionSidebar"
              >
                <div className="bg-white py-2 collapse-inner rounded">
                  <Show when="feature:wms-orders-show">
                    <Link className="collapse-item" to="/WMS/order">
                      Gestión de Órdenes
                    </Link>
                  </Show>
                  <Show when="feature:read-lot">
                    <Link className="collapse-item" to="/lots">
                      Lotes
                    </Link>
                  </Show>
                  <Show when="feature:show-branch">
                    <Link className="collapse-item" to="/branch">
                      HUBs
                    </Link>
                  </Show>

                  <Show when="feature:warehouse-show-module">
                    <Link className="collapse-item" to="/warehouse">
                      Bodegas
                    </Link>
                  </Show>
                  <Show when="feature:read-product">
                    <Link className="collapse-item" to="/products">
                      Productos
                    </Link>
                  </Show>
                  <Show when="feature:wms-inventory-module-show">
                    <Link className="collapse-item" to="/inventory">
                      Inventario
                    </Link>
                  </Show>
                  {/* <Show when="feature:read-lotproduct">
                    <Link className="collapse-item" to="/lotproducts">
                      Lotes - Productos
                    </Link>
                  </Show> */}
                  <Show when="feature:read-category">
                    <Link className="collapse-item" to="/categories">
                      Categorías
                    </Link>
                  </Show>
                </div>
              </div>
            </li>
          </Show>
          <Show when="feature:dsp-menu-show">
            <li className="nav-item">
              <a
                className="nav-link collapsed"
                href="#"
                data-bs-toggle="collapse"
                data-bs-target="#collapseDsp"
                aria-controls="collapseDsp"
              >
                <i className="fas fa-fw fa-truck"></i>
                <span>DSP</span>
              </a>
              <div
                id="collapseDsp"
                className="collapse"
                aria-labelledby="collapseDsp"
                data-parent="#accordionSidebar"
              >
                <div className="bg-white py-2 collapse-inner rounded">
                  <Show when="feature:dsp-show-module">
                    <Link className="collapse-item" to="/DSP/order">
                      Gestión de Órdenes
                    </Link>
                  </Show> 
                  <Show when="feature:dsp-assign-messenger">
                    <Link className="collapse-item" to="/DSP/assignCourier">
                      Asignar Órdenes
                    </Link>
                  </Show> 
                  <Show when="feature:dsp-assign-messenger">
                    <Link className="collapse-item" to="/DSP/assignOrders">
                      Órdenes Asignadas
                    </Link>
                  </Show>
                </div>
              </div>
            </li>
          </Show>
          <Show when="feature:menu-picking">
            <li className="nav-item">
              <a
                className="nav-link collapsed"
                href="#"
                data-bs-toggle="collapse"
                data-bs-target="#collapse4"
                aria-controls="collapse4"
              >
                <i className="fas fa-fw fa-cog"></i>
                <span>Picking y Packing</span>
              </a>
              <div
                id="collapse4"
                className="collapse"
                aria-labelledby="collapse4"
                data-parent="#accordionSidebar"
              >
                <div className="bg-white py-2 collapse-inner rounded">
                  <Show when="feature:read-customer">
                    <Link className="collapse-item" to="/customers">
                      Clientes
                    </Link>
                  </Show>
                  <Show when="feature:read-order">
                    <Link className="collapse-item" to="/orders">
                      Órdenes
                    </Link>
                  </Show>
                  <Show when="feature:read-order">
                    <Link className="collapse-item" to="/ordersAssigned">
                      Órdenes asignadas
                    </Link>
                  </Show>
                </div>
              </div>
            </li>
          </Show>
          <Show when="feature:show-financial">
            <li className="nav-item">
              <a
                className="nav-link collapsed"
                href="#"
                data-bs-toggle="collapse"
                data-bs-target="#collapse5"
                aria-controls="collapse5"
              >
                <i className="fas fa-fw fa-coins"></i>
                <span>Financiera</span>
              </a>
              <div
                id="collapse5"
                className="collapse"
                aria-labelledby="collapse5"
                data-parent="#accordionSidebar"
              >
                <div className="bg-white py-2 collapse-inner rounded">
                  <Show when="feature:show-collectconfirm">
                    <Link className="collapse-item" to="/ConfirmCollect">
                      Confirmación de recaudo
                    </Link>
                  </Show>
                </div>
              </div>
            </li>
          </Show>
          <Show when="feature:show-auditoria">
            <li className="nav-item">
              <a
                className="nav-link collapsed"
                href="#"
                data-bs-toggle="collapse"
                data-bs-target="#collapse6-1"
                aria-controls="collapse6-1"
              >
                <i className="fas fa-fw fa-newspaper"></i>
                <span>Auditoría</span>
              </a>
              <div
                id="collapse6-1"
                className="collapse"
                aria-labelledby="collapse6-1"
                data-parent="#accordionSidebar"
              >
                <div className="bg-white py-2 collapse-inner rounded">
                  <Show when="feature:show-collectconfirm">
                    <Link className="collapse-item" to="/messageReport">
                      Reporte envio de mensaje
                    </Link>
                  </Show>
                  <Show when="feature:show-menu-cartaporte-log">
                    <Link className="collapse-item" to="/cartaPorteLog">
                      Log de Carta-Porte
                    </Link>
                  </Show>
                </div>
              </div>
            </li>
          </Show>
          {/* <Show when="feature:menu-collect">
            <li className="nav-item">
              <a
                className="nav-link collapsed"
                href="#"
                data-bs-toggle="collapse"
                data-bs-target="#collapse5"
                aria-controls="collapse5"
              >
                <i className="fas fa-fw fa-cog"></i>
                <span>Recaudos</span>
              </a>
              <div
                id="collapse5"
                className="collapse"
                aria-labelledby="collapse5"
                data-parent="#accordionSidebar"
              >
                <div className="bg-white py-2 collapse-inner rounded">
                  <Show when="feature:read-collect">
                    <Link className="collapse-item" to="/collects">
                      Gestión recaudos
                    </Link>
                  </Show>
                </div>
              </div>
            </li>
          </Show> */}

          <Show when="feature:menu-masters">
            <hr className="sidebar-divider" />
            <div className="sidebar-heading">Configuración</div>
            <li className="nav-item">
              <a
                className="nav-link collapsed"
                href="#"
                data-bs-toggle="collapse"
                data-bs-target="#collapse1"
                aria-controls="collapse1"
              >
                <i className="fas fa-fw fa-cog"></i>
                <span>Maestros</span>
              </a>
              <div
                id="collapse1"
                className="collapse"
                aria-labelledby="collapse1"
                data-parent="#accordionSidebar"
              >
                <div className="bg-white py-2 collapse-inner rounded">
                  <Show when="feature:alerts-module-show">
                    <Link className="collapse-item" to="/alerts">
                      Alertas
                    </Link>
                  </Show>
                  <Show when="feature:manage-status">
                    <Link className="collapse-item" to="/statuses">
                      Estados
                    </Link>
                  </Show>
                  <Show when="feature:read-country">
                    <Link className="collapse-item" to="/countries">
                      Paises
                    </Link>
                  </Show>
                  <Show when="feature:read-department">
                    <Link className="collapse-item" to="/departments">
                      Departamentos
                    </Link>
                  </Show>
                  <Show when="feature:read-city">
                    <Link className="collapse-item" to="/cities">
                      Ciudades
                    </Link>
                  </Show>
                  <Show when="feature:read-area">
                    <Link className="collapse-item" to="/areas">
                      Áreas
                    </Link>
                  </Show>
                  <Show when="feature:read-zone">
                    <Link className="collapse-item" to="/zones">
                      Zonas
                    </Link>
                  </Show>
                  <Show when="feature:read-neighborhood">
                    <Link className="collapse-item" to="/neighborhoods">
                      Barrios
                    </Link>
                  </Show>
                  <Show when="feature:read-zoneneighborhood">
                    <Link className="collapse-item" to="/zoneneighborhoods">
                      Zonas - Barrios
                    </Link>
                  </Show>
                  <Show when="feature:read-company">
                    <Link className="collapse-item" to="/companies">
                      Empresas
                    </Link>
                  </Show>
                  <Show when="feature:read-headquarters">
                    <Link className="collapse-item" to="/headquarters">
                      Sedes
                    </Link>
                  </Show>
                  <Show when="feature:read-record">
                    <Link className="collapse-item" to="/novelties">
                      Novedades
                    </Link>
                  </Show>
                  <Show when="feature:read-note">
                    <Link className="collapse-item" to="/notes">
                      Notas
                    </Link>
                  </Show>
                  <Show when="feature:read-addressCardinal">
                    <Link className="collapse-item" to="/addressCardinal">
                      Orientación
                    </Link>
                  </Show>
                  <Show when="feature:read-addressInsideType">
                    <Link className="collapse-item" to="/addressInsideType">
                      Tipos de interior
                    </Link>
                  </Show>
                  <Show when="feature:read-addressNomenclature">
                    <Link className="collapse-item" to="/addressNomenclature">
                      Apéndices
                    </Link>
                  </Show>
                  <Show when="feature:read-addressRoadType">
                    <Link className="collapse-item" to="/addressRoadType">
                      Indicativos de Via
                    </Link>
                  </Show>
                  <Show when="feature:coverage-show-module">
                    <Link className="collapse-item" to="/coverage">
                      Matriz de Cobertura
                    </Link>
                  </Show>
                </div>
              </div>
            </li>
          </Show>
          <Show when="feature:menu-mapping">
            <li className="nav-item">
              <a
                className="nav-link collapsed"
                href="#"
                data-bs-toggle="collapse"
                data-bs-target="#collapse6"
                aria-controls="collapse6"
              >
                <i className="fa fa-sitemap fa-fw"></i>
                <span>Mapeo entidades externas</span>
              </a>
              <div
                id="collapse6"
                className="collapse"
                aria-labelledby="collapse6"
                data-parent="#accordionSidebar"
              >
                <div className="bg-white py-2 collapse-inner rounded">
                  <Show when="feature:read-mapping-cities">
                    <Link className="collapse-item" to="/mappingCity">
                      Ciudades
                    </Link>
                  </Show>
                  <Show when="feature:read-mapping-customers">
                    <Link className="collapse-item" to="/mappingCustomer">
                      Clientes
                    </Link>
                  </Show>
                </div>
              </div>
            </li>
          </Show>
          <Show when="feature:menu-security">
            <li className="nav-item">
              <a
                className="nav-link collapsed"
                href="#"
                data-bs-toggle="collapse"
                data-bs-target="#collapse2"
                aria-controls="collapse2"
              >
                <i className="fas fa-shield-alt"></i>
                <span>Seguridad</span>
              </a>
              <div
                id="collapse2"
                className="collapse"
                aria-labelledby="collapse2"
                data-parent="#accordionSidebar"
              >
                <div className="bg-white py-2 collapse-inner rounded">
                  <Show when="feature:read-role">
                    <Link className="collapse-item" to="/roles">
                      Roles
                    </Link>
                  </Show>
                  <Show when="feature:read-permission">
                    <Link className="collapse-item" to="/permissions">
                      Permisos
                    </Link>
                  </Show>
                  <Show when="feature:read-user">
                    <Link className="collapse-item" to="/users">
                      Usuarios
                    </Link>
                  </Show>
                </div>
              </div>
            </li>
          </Show>
          <Show when="feature:menu-reports">
            <li className="nav-item">
              <a
                className="nav-link collapsed"
                href="#"
                data-bs-toggle="collapse"
                data-bs-target="#collapse3"
                aria-controls="collapse3"
              >
                <i className="fas fa-address-card"></i>
                <span>Reportes</span>
              </a>
              <div
                id="collapse3"
                className="collapse"
                aria-labelledby="collapse3"
                data-parent="#accordionSidebar"
              >
                <div className="bg-white py-2 collapse-inner rounded">
                  <Show when="feature:read-reports">
                    <Link className="collapse-item" to="/recordsReport">
                      Novedades
                    </Link>
                  </Show>
                  {/* <Show when="feature:read-collects-report">
                  <Link className="collapse-item" to="/collectsReport" style={{marginLeft: '0px', marginRight: '0px', paddingRight: '8px', paddingLeft: '8px'}}>
                    Reporte de Recaudos
                  </Link>
                </Show> */}
                  <Show when="feature:read-sessions-report">
                    <Link className="collapse-item" to="/sessionsReport">
                      Sesión de Mensajeros
                    </Link>
                  </Show>
                  <Show when="feature:read-conciliation-report">
                    <Link className="collapse-item" to="/conciliationsReport">
                      Conciliaciones
                    </Link>
                  </Show>
                  <Show when={"feature:show-closeConfirmation-report"}>
                    <Link className="collapse-item" to="/addressFinishedReport">
                      Reporte de Cierre
                    </Link>
                  </Show>
                  <Show when="feature:show-rescheduling-report">
                    <Link className="collapse-item" to="/reschedulingReport">
                      Reprogramaciones
                    </Link>
                  </Show>
                  <Show when="feature:show-collectconfirm-report">
                    <Link className="collapse-item" to="/confirmCollectionReport">
                      Confirmación de recaudo
                    </Link>
                  </Show>
                  <Show whenMultiple={["feature:report-confirmcollection-shipper-show", "feature:isClient"]}>
                    <Link className="collapse-item" to="/confirmCollectionShipperReport">
                      Confirmación de recaudo
                    </Link>
                  </Show>
                </div>
              </div>
            </li>
          </Show>          
          <hr className="sidebar-divider" />
          <Show>
            <li className="nav-item" style={{marginBottom: '5px', padding: '5px', borderRadius: '8px', boxShadow: '0 2px 2px rgba(0,0,0,0.1)'}}>
            <div className="bg-white py-2 collapse-inner rounded">
              <a
                className="nav-link collapsed"
                href="#"
                data-bs-toggle="collapse"
                data-bs-target="#collapsed11"
                aria-expanded="false"
                aria-controls="collapsed11"
              >
                {session && session.userImage ? (
                  <img src={session.userImage} alt="User Avatar" className="img-profile rounded-circle" style={{ width: '60px', height: '60px'}} />
                ) : (
                  <i className="fas fa-user-circle" style={{ fontSize: '2.5rem'}}></i>
                )}
                <span style={{padding: '10px'}}> {session && `${session.firstName} ${session.lastName}`}</span>
              </a>              
              <div
                id="collapsed11"
                className="collapse"
                aria-labelledby="collapsed11"
                data-parent="#accordionSidebar"
              >
                <div className="bg-white collapse-inner rounded">
                  <Link
                    className="collapse-item"
                    to="/"
                    onClick={onLogout}
                  >
                    <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-600"></i>
                    Cerrar sesión
                  </Link>
                </div>
              </div>
              </div>
            </li>
          </Show>
          <hr className="sidebar-divider" />
      </ul>
    );
  }

export default Sidebar;
