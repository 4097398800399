import React, { useState, forwardRef, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import Grid from '../../../../Grid';
import Swal from 'sweetalert2';
import { connect } from "react-redux";
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

const ReturnsModal = forwardRef(({ 
  returnProducts, 
  scannedGuide,
  onOpenModal,
  idReturn,
  reduxConfirmReturn,
  reduxProcessReturn,
  reduxProcessReturnOrder,
  successfulProcessReturn,
  processReturnResult,
  successfulProcessReturnOrder,
  processReturnOrderResult,
  show,
  onHide,
  ...props 
}, ref) => {
  const [filesWatcher, setFilesWatcher] = useState([]);
  const [comments, setComments] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [localReturnProducts, setLocalReturnProducts] = useState([]);
  const [processedOrders, setProcessedOrders] = useState([]);
  const [scannedGuides, setScannedGuides] = useState([]);
  const [inputScannedGuide, setInputScannedGuide] = useState('');
  const [initialized, setInitialized] = useState(false);
  const [incrementableValues, setIncrementableValues] = useState({});

  useEffect(() => {
    if (show && !initialized) {
      reduxProcessReturn(idReturn);
      setInitialized(true);
    }
  }, [show, idReturn, initialized]);

  useEffect(() => {
    if (successfulProcessReturn && processReturnResult?.returnDetail && !initialized) {
      processReturnResult.returnDetail.forEach(returnDetail => {
        const trackingOrReturn = returnDetail.returnTrackingId || returnDetail.trackingId;
        
        if (trackingOrReturn) {
          reduxProcessReturnOrder({
            idReturn: processReturnResult.idReturn,
            trackingOrReturn
          });
        }
      });
      setInitialized(true);
    }
  }, [successfulProcessReturn, processReturnResult, initialized]);

  useEffect(() => {
    if (successfulProcessReturnOrder && processReturnOrderResult?.returnDetail?.[0] && !initialized) {
      const returnDetail = processReturnOrderResult.returnDetail[0];
      const tracking = returnDetail.order?.address?.returnTrackingId || 
                      returnDetail.order?.address?.trackingId;
      
      if (tracking) {
        const orderProducts = returnDetail.order?.orderDetails?.map(detail => ({
          tracking: tracking,
          numOrder: returnDetail.order.numOrder,
          product: detail.product.description,
          quantity: detail.quantity,
          idProduct: detail.product.idProduct,
          idAddress: returnDetail.order?.address?.idAddress
        })) || [];
        
        setProcessedOrders(prevOrders => {
          const existingOrderIndex = prevOrders.findIndex(
            order => order[0]?.numOrder === returnDetail.order.numOrder
          );
          
          if (existingOrderIndex >= 0) {
            const newOrders = [...prevOrders];
            newOrders[existingOrderIndex] = orderProducts;
            return newOrders;
          }
          
          return [...prevOrders, orderProducts];
        });
      }
      setInitialized(true);
    }
  }, [successfulProcessReturnOrder, processReturnOrderResult, initialized]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Enter') {
        onOpenModal(true);
      }
    };

    window.addEventListener('keypress', handleKeyPress);

    return () => {
      window.removeEventListener('keypress', handleKeyPress);
    };
  }, [onOpenModal]);

  useEffect(() => {
    if (scannedGuide) {
        const guideExists = returnProducts.some(product => product.tracking === scannedGuide);
        if (guideExists && !scannedGuides.includes(scannedGuide)) {
            setScannedGuides([...scannedGuides, scannedGuide]);
        }
    }
  }, [scannedGuide, returnProducts]);

  useEffect(() => {
    if (!show) {
      // No reiniciar el estado de los productos al cerrar la modal
      // setFilesWatcher([]);
      // setComments('');
      // setScannedGuides([]);
      
      // Limpiar solo los inputs numéricos
      const inputs = document.querySelectorAll('input[type="number"]');
      inputs.forEach(input => {
        input.value = "0";
      });
    }
  }, [show]);

  const handleCloseModal = () => {
    onHide(false);
    setInitialized(false);
    setIncrementableValues({}); // Reiniciar los valores incrementables a 0
    setScannedGuides([]); // Limpiar las guías escaneadas
    // No reiniciar el estado de los productos al cerrar la modal
    // setFilesWatcher([]);
    // setComments('');
    
    const inputs = document.querySelectorAll('input[type="number"]');
    inputs.forEach(input => {
      input.value = "0";
    });
  };

  const handleChangeFiles = (event) => {
    const newFile = event.target.files[0];
    if (newFile && newFile.type.startsWith('image/')) {
      setFilesWatcher([newFile]);
      Swal.fire({
        title: 'Archivo Cargado',
        text: 'Su archivo ha sido cargado exitosamente.',
        icon: 'success',
        confirmButtonText: 'Aceptar'
      });
    }
  };

  const handleRemoveFile = (index) => {
    setFilesWatcher(prevFiles => prevFiles.filter((_, i) => i !== index));
  };

  const handleScanGuide = () => {
    const guide = inputScannedGuide.trim();
    if (guide && !scannedGuides.includes(guide)) {
      setScannedGuides([...scannedGuides, guide]);
      setInputScannedGuide(''); // Limpiar el campo de entrada
    } else {
      Swal.fire({
        title: 'Error',
        text: 'La guía ya ha sido escaneada o está vacía.',
        icon: 'error',
        confirmButtonText: 'Aceptar'
      });
    }
  };

  const handleRemoveGuide = (guideToRemove) => {
    setScannedGuides(scannedGuides.filter(guide => guide !== guideToRemove));
  };

  const handleSave = async () => {
    const filteredProducts = returnProducts.filter(product => 
      scannedGuides.includes(product.tracking)
    );

    const productsWithErrors = [];
    const productsWithQuantityErrors = [];

    filteredProducts.forEach(product => {
      const rowInputs = {
        available: parseInt(document.querySelector(`input[name="available"][data-product-id="${product.idProduct}"][data-guide="${product.tracking}"]`)?.value) || 0,
        damaged: parseInt(document.querySelector(`input[name="damaged"][data-product-id="${product.idProduct}"][data-guide="${product.tracking}"]`)?.value) || 0,
        missing: parseInt(document.querySelector(`input[name="missing"][data-product-id="${product.idProduct}"][data-guide="${product.tracking}"]`)?.value) || 0,
        extra: parseInt(document.querySelector(`input[name="extra"][data-product-id="${product.idProduct}"][data-guide="${product.tracking}"]`)?.value) || 0,
        expired: parseInt(document.querySelector(`input[name="expired"][data-product-id="${product.idProduct}"][data-guide="${product.tracking}"]`)?.value) || 0,
        supplanted: parseInt(document.querySelector(`input[name="supplanted"][data-product-id="${product.idProduct}"][data-guide="${product.tracking}"]`)?.value) || 0
      };

      const hasValue = Object.values(rowInputs).some(value => value > 0);
      const totalSum = Object.values(rowInputs).reduce((sum, value) => sum + value, 0);
      
      if (!hasValue) {
        productsWithErrors.push({
          guide: product.tracking,
          product: product.product,
          declaredQuantity: product.declaredQuantity
        });
      }

      if (totalSum < product.quantity) {
        productsWithQuantityErrors.push({
          guide: product.tracking,
          product: product.product,
          declared: product.quantity,
          total: totalSum
        });
      }
    });

    if (productsWithErrors.length > 0) {
      Swal.fire({
        title: 'Campos requeridos',
        text: 'Debe ingresar al menos un valor en alguno de los campos numéricos.',
        icon: 'warning',
        confirmButtonText: 'Aceptar'
      });
      return;
    }

    if (productsWithQuantityErrors.length > 0) {
      Swal.fire({
        title: 'Error en cantidades',
        text: 'La suma de las cantidades ingresadas no puede ser menor a la cantidad total declarada.',
        icon: 'warning',
        confirmButtonText: 'Aceptar'
      });
      return;
    }

    if (!comments.trim()) {
      Swal.fire({
        title: 'Error',
        text: 'Debe agregar un comentario u observación',
        icon: 'error',
        confirmButtonText: 'Aceptar'
      });
      return;
    }

    if (scannedGuide && filesWatcher.length !== 1) {
      Swal.fire({
        title: 'Error',
        text: `Para la orden ${scannedGuide} se necesita exactamente una imagen de evidencia`,
        icon: 'error',
        confirmButtonText: 'Aceptar'
      });
      return;
    }

    const editedData = filteredProducts.map(product => ({
        tracking: product.tracking,
        product: product.product,
        available: parseInt(document.querySelector(`input[name="available"][data-product-id="${product.idProduct}"][data-guide="${product.tracking}"]`)?.value) || 0,
        damaged: parseInt(document.querySelector(`input[name="damaged"][data-product-id="${product.idProduct}"][data-guide="${product.tracking}"]`)?.value) || 0,
        missing: parseInt(document.querySelector(`input[name="missing"][data-product-id="${product.idProduct}"][data-guide="${product.tracking}"]`)?.value) || 0,
        extra: parseInt(document.querySelector(`input[name="extra"][data-product-id="${product.idProduct}"][data-guide="${product.tracking}"]`)?.value) || 0,
        expired: parseInt(document.querySelector(`input[name="expired"][data-product-id="${product.idProduct}"][data-guide="${product.tracking}"]`)?.value) || 0,
        supplanted: parseInt(document.querySelector(`input[name="supplanted"][data-product-id="${product.idProduct}"][data-guide="${product.tracking}"]`)?.value) || 0,
        comments: comments.trim(),
        evidences: filesWatcher
    }));

    console.log("Datos editados enviados al componente padre:", editedData);

    props.onSave(editedData);

    
    setFilesWatcher([]); 
    setComments(''); 
    setScannedGuides([]); 
    setInputScannedGuide(''); 

    handleCloseModal();
  };

  const handleIncrement = (name, item, increment) => {
    setIncrementableValues(prevValues => {
      const currentValue = prevValues[item.idProduct]?.[name] || 0;
      return {
        ...prevValues,
        [item.idProduct]: {
          ...prevValues[item.idProduct],
          [name]: Math.max(0, currentValue + increment)
        }
      };
    });
  };

  const IncrementableInput = ({ name, item, incrementableValues, setIncrementableValues }) => {
    const [tempValue, setTempValue] = useState(""); // Estado temporal para la edición

    // Sincroniza el estado local con el estado global al cargar el componente o al actualizar `incrementableValues`
    useEffect(() => {
      const currentValue = incrementableValues[item.idProduct]?.[name] || 0;
      setTempValue(currentValue.toString());
    }, [incrementableValues, item.idProduct, name]);

    const handleInputChange = (e) => {
      const newValue = e.target.value;
      if (/^\d*$/.test(newValue)) {
        // Permite solo números
        setTempValue(newValue); // Actualiza el estado local mientras escribes
      }
    };

    const handleBlur = () => {
      // Al salir del campo, guarda el valor en el estado global
      const parsedValue = parseInt(tempValue, 10) || 0;
      setTempValue(parsedValue.toString()); // Normaliza el valor local
      setIncrementableValues((prevValues) => ({
        ...prevValues,
        [item.idProduct]: {
          ...prevValues[item.idProduct],
          [name]: parsedValue,
        },
      }));
    };

    const handleIncrement = (increment) => {
      const currentValue = parseInt(tempValue, 10) || 0;
      const newValue = Math.max(0, currentValue + increment); // Asegura que no sea negativo
      setTempValue(newValue.toString()); // Actualiza el estado local
      setIncrementableValues((prevValues) => ({
        ...prevValues,
        [item.idProduct]: {
          ...prevValues[item.idProduct],
          [name]: newValue,
        },
      }));
    };

    return (
      <div className="incrementable-container d-flex align-items-center">
        <input
          type="number"
          min="0"
          value={tempValue}
          name={name}
          data-product-id={item.idProduct}
          data-guide={item.tracking}
          className="form-control-sm mx-1 incrementable-input"
          onChange={handleInputChange} // Actualiza el valor temporal mientras escribes
          onBlur={handleBlur} // Guarda el valor en el estado global al salir del campo
        />
        <div className="incrementable-buttons">
          <button
            type="button"
            className="btn btn-outline-secondary btn-sm increment-button"
            onClick={() => handleIncrement(1)} // Incrementa en 1
          >
            <i className="fas fa-chevron-up"></i>
          </button>
          <button
            type="button"
            className="btn btn-outline-secondary btn-sm decrement-button"
            onClick={() => handleIncrement(-1)} // Decrementa en 1
          >
            <i className="fas fa-chevron-down"></i>
          </button>
        </div>
      </div>
    );
  };

  const shownGuides = new Set();

  const renderGuideAndQuantityCard = (product) => {
    if (shownGuides.has(product.tracking)) {
      return null; 
    }

    shownGuides.add(product.tracking);

    const totalQuantity = returnProducts
      .filter(p => p.tracking === product.tracking)
      .reduce((sum, p) => sum + p.quantity, 0);
  };

  const columns = [
    // {
    //   title: "Producto",
    //   field: "product",
    //   searchable: false,
    //   render: (item) => (
    //     <span className="product-name" style={{ cursor: 'pointer' }}>
    //       {item.product}
    //     </span>
    //   ),
    // },
    // {
    //   title: "Cant.",
    //   field: "quantity",
    //   searchable: false,
    //   render: (item) => <span>{item.quantity}</span>,
    // },
    {
      title: "Disponible",
      field: "disponible",
      searchable: false,
      render: (item) => <IncrementableInput name="available" item={item} incrementableValues={incrementableValues} setIncrementableValues={setIncrementableValues} />,
    },
    {
      title: "Averiado",
      field: "averiado",
      searchable: false,
      render: (item) => <IncrementableInput name="damaged" item={item} incrementableValues={incrementableValues} setIncrementableValues={setIncrementableValues} />,
    },
    {
      title: "Faltante",
      field: "Faltante",
      searchable: false,
      render: (item) => <IncrementableInput name="missing" item={item} incrementableValues={incrementableValues} setIncrementableValues={setIncrementableValues} />,
    },
    {
      title: "Sobrante",
      field: "sobrante",
      searchable: false,
      render: (item) => <IncrementableInput name="extra" item={item} incrementableValues={incrementableValues} setIncrementableValues={setIncrementableValues} />,
    },
    {
      title: "Caducados",
      field: "caducados",
      searchable: false,
      render: (item) => <IncrementableInput name="expired" item={item} incrementableValues={incrementableValues} setIncrementableValues={setIncrementableValues} />,
    },
    {
      title: "Suplantación",
      field: "suplantacion",
      searchable: false,
      render: (item) => <IncrementableInput name="supplanted" item={item} incrementableValues={incrementableValues} setIncrementableValues={setIncrementableValues} />,
    }
  ];

  const renderGrid = () => {
    const filteredProducts = returnProducts.filter(product => 
      scannedGuides.includes(product.tracking)
    );
    
    return (
      <>
        <div className="product-grid-container" style={{ maxHeight: '70vh', overflowY: 'auto'}}>
          {filteredProducts.map(product => (
            <div key={product.tracking} className="card mb-3">
              {renderGuideAndQuantityCard(product)}
              <div className="card-body">
                <div className="product-info">
                  <h5 className="h6">Producto: {product.product} | <strong><span >Cantidad: {product.quantity}</span></strong></h5>
                </div>
                <Grid
                  cols={columns}
                  data={[product]}
                  showFilters={false}
                  showRange={false}
                />
              </div>
            </div>
          ))}
        </div>
      </>
    );
  };

  const renderFileNames = () => (
    <>
      {filesWatcher.length > 0 && (
        <div className="custom-multifile-names-container">
          {filesWatcher.map((file, index) => (
            <span className="custom-multifile-names" key={index}>
              {file.name}
              <button
                className={`custom-multifile-remove btn btn-sm btn-danger`}
                onClick={() => handleRemoveFile(index)}
              >
                Eliminar
              </button>
            </span>
          ))}
        </div>
      )}
    </>
  );

  return (
    <>
      <div 
        style={{ width: '100%', height: '50px', cursor: 'pointer' }}
      >
        <input
          className="form-control"
          ref={ref}
          style={{ display: 'none' }}
          {...props}
        />
      </div>

      <Modal
        show={show}
        onHide={handleCloseModal}
        backdrop="static"
        keyboard={false}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Detalles de la devolución | <h6 style={{ display: 'inline' }}>Guía: {scannedGuide} <small> (Cantidad total de productos: {returnProducts.filter(product => scannedGuides.includes(product.tracking)).reduce((sum, product) => sum + product.quantity, 0)})</small></h6>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          

          {renderGrid()}
          
          <Form.Group >
            <Form.Label>Evidencias</Form.Label>
            <div className="d-flex flex-column">
              <label htmlFor="custom-multifile-input" className="custom-multifile-label">Seleccionar archivos</label>
              <input
                id='custom-multifile-input'
                className="custom-multifile-input"
                type="file" 
                multiple
                accept="image/*"
                onChange={handleChangeFiles}
              />
              {renderFileNames()}
            </div>
          </Form.Group>

          <Form.Group className="mt-3">
            <Form.Label>Comentarios/Observaciones</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={comments}
              onChange={(e) => setComments(e.target.value)}
            />
          </Form.Group>

          <Form.Group>
            <Form.Control
              type="text"
              value={inputScannedGuide}
              onChange={(e) => setInputScannedGuide(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleScanGuide();
                }
              }}
              style={{ display: 'none' }}
            />
          </Form.Group>

          {/* <div>
            <h6>Guías Escaneadas:</h6>
            {scannedGuides.map((guide, index) => (
              <div key={index} className="d-flex justify-content-between">
                <span>{guide}</span>
                <Button variant="danger" size="sm" onClick={() => handleRemoveGuide(guide)}>Eliminar</Button>
              </div>
            ))}
          </div> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal} disabled={isProcessing}>
            Cerrar
          </Button>
          <Button variant="primary" onClick={handleSave} disabled={isProcessing}>
            {isProcessing ? 'Procesando...' : 'Guardar'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
});

ReturnsModal.propTypes = {
  returnProducts: PropTypes.array,
  idReturn: PropTypes.string,
  reduxConfirmReturn: PropTypes.func.isRequired,
  reduxProcessReturn: PropTypes.func.isRequired,
  reduxProcessReturnOrder: PropTypes.func.isRequired,
  successfulProcessReturn: PropTypes.bool,
  processReturnResult: PropTypes.object,
  successfulProcessReturnOrder: PropTypes.bool,
  processReturnOrderResult: PropTypes.object,
  show: PropTypes.bool,
  onHide: PropTypes.func,
  scannedGuide: PropTypes.string,
  onOpenModal: PropTypes.func,
};

const mapStateToProps = (state) => ({
  successfulProcessReturn: state.wmsState.successfulProcessReturn,
  processReturnResult: state.wmsState.processReturnResult,
  successfulProcessReturnOrder: state.wmsState.successfulProcessReturnOrder,
  processReturnOrderResult: state.wmsState.processReturnOrderResult
});

const mapDispatchToProps = (dispatch) => ({
  reduxProcessReturn: (idReturn) =>
    dispatch({
      type: "PROCESS_WMS_RETURN_REQUEST",
      value: idReturn,
    }),
  reduxProcessReturnOrder: (payload) =>
    dispatch({
      type: "PROCESS_WMS_RETURN_ORDER_REQUEST",
      value: payload,
    })
});

export default connect(mapStateToProps, mapDispatchToProps)(ReturnsModal);

