import { React, useEffect, useState } from 'react';
import { connect } from "react-redux";
import { Modal, Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { Controller, useForm } from "react-hook-form";
import DatePicker from 'react-datepicker';
import { isNullOrUndefined } from "../../../../../utils/functionsUtils";
import Grid from '../../../../Grid';
import Swal from "sweetalert2";

import CustomTooltip from "../../../../CustomTooltip";
import { formatDateWithoutTimeZone } from '../../../../../utils/formatDate';

const InventoryLots = ({ 
    show, 
    lotsInfo,    
    reduxGetLots,
    lots,
    lot,
    reduxCreateLot,
    handleClose,    
    reduxGetWarehouses,
    reduxGetWarehouseById,
    rowEdited,
    reduxGetProductLocation,
    productLocations,
    reduxResetLots,
    reduxGetWarehouseInventoryByShipper,
    successfulCreateLots,
    errorCreateLots,
}) => {
    const {        
        control,
        handleSubmit,
        reset,
        formState: { errors },
        setValue,
    } = useForm({
        defaultValues: { 
            number: null, 
            serial: null, 
            dateExp: null, 
            quantity: null
        }
    });

    const [showLots, setShowLots] = useState(false);
    const [showLotsGrind, setShowLotsGrind] = useState(true);
    const [combinedData, setCombinedData] = useState({});
    const [offset, setOffset] = useState(10);
    const [search, setSearch] = useState('');
    const [expiredChecked, setExpiredChecked] = useState(null);
    const [spentChecked, setSpentChecked] = useState(null);

    useEffect(() => {
        reset();
        if (show && lotsInfo) {
            reduxGetLots({
                idProduct: lotsInfo.idProduct,
                page: 1,
                offset: null,
                search: null,
                spent: null,
                expired: null
            });
            reduxGetWarehouses({ page: 1, offset: 1000 });
            if (!isNullOrUndefined(lotsInfo)) {
                if (lotsInfo.idWarehouse) {
                    reduxGetWarehouseById({ id: lotsInfo.idWarehouse });
                }
                if (lotsInfo.idProduct && lotsInfo.idWarehouse) {
                    reduxGetProductLocation({ idProduct: lotsInfo.idProduct,
                        idWarehouse: lotsInfo.idWarehouse });
                }
            }
        }
        handleCloseCreate();
    }, [show, lotsInfo, reduxGetLots, reset, reduxGetWarehouses, reduxGetWarehouseById, reduxGetProductLocation]);

    useEffect(() => {
        if (!isNullOrUndefined(rowEdited)) {
            setValue('warehouse', {
                value: rowEdited.idWarehouse,
                label: `${rowEdited.name || rowEdited.warehouseCode}`,
            });
        }
    }, [rowEdited, setValue]);

    const handleShow = () => {        
            setShowLots(true);
            setShowLotsGrind(false);
    };

    const handleCloseCreate = () => {
        setShowLots(false);        
        setShowLotsGrind(true);        
        reset();
        setValue('number', '');
        setValue('serial', '');
        setValue('dateExp', '');
        setValue('quantity', '');
        setExpiredChecked(null);
        setSpentChecked(null);

        if (show && lotsInfo) {
            reduxGetLots({
                idProduct: lotsInfo.idProduct,
                page: 1,
                offset: null,
                search: null,
                spent: null,
                expired: null
            });

            reduxGetProductLocation({ idProduct: lotsInfo.idProduct, idWarehouse: lotsInfo.idWarehouse });
        }
    };
     
    const onSubmit = (data) => {
        const { number, serial, dateExp, quantity, col, stowage, rack, level } = data;
    
        const quantityFormatted = Number(quantity);
        reduxCreateLot({
            number, 
            idProduct: lotsInfo.idProduct, 
            serial,
            dateExp,
            status: 1,
            quantity: quantityFormatted,
        });         
    };

    useEffect(() => {
        if (successfulCreateLots === true) {
            reduxGetLots({
                idProduct: lotsInfo.idProduct,
                page: 1,
                offset: offset,
                search: null,
                spent: null,
                expired: null,
            });        
    
            reduxGetWarehouseInventoryByShipper({
                page: 1,
                shipper: lotsInfo.idCompany,
                warehouses: lotsInfo.idWarehouse,
            });
            reduxGetProductLocation({ idProduct: lotsInfo.idProduct, idWarehouse: lotsInfo.idWarehouse  });
            
            handleCloseCreate();
            
        } 

        if (errorCreateLots === true) {
            Swal.fire({
                title: 'Error al crear Lote',
                text: lot.message || 'Ha ocurrido un error',
                icon: 'error',
                showConfirmButton: true,
                confirmButtonText: "OK",
            }).then(result => {
                if (!result.isConfirmed) return;               
                reduxResetLots();
            })            
        }
        

    }, [lot, successfulCreateLots, errorCreateLots])
    

    const performSearch = (field, value, checkboxType) => {
        
        if (checkboxType === 'expired') {
            setExpiredChecked(value);
            setSpentChecked(false);
        } else {
            setExpiredChecked(false);
            setSpentChecked(value);
        }
        
        field.onChange(value);
    
        const spentValue = checkboxType === 'spent' && value ? value : null;
        const expiredValue = checkboxType === 'expired' && value ? value : null;
    
        reduxGetLots({
            idProduct: lotsInfo.idProduct,
            page: 1,
            offset: offset,
            search: search,
            spent: spentValue,
            expired: expiredValue,
        });
    };

    
    const combineData = (lots) => {
        if (!lots || !Array.isArray(lots.items)) {
            return null;
        }
    
        return {
            ...lots,
            items: lots.items.map(item => ({
                ...item
            }))
        };
    };

    useEffect(() => {
        if (lots && lots.items && Array.isArray(lots.items)) {
            const combined = combineData(lots);
            if (combined) {
                setCombinedData(combined);
            }
        }
    }, [lots]);
    

    const columns = [
        {
            title: "# Lote",
            render: (rowData) => {
                return <span>{rowData.idLot}</span>;
            },
            field: "idLot",
            searchable: true,
        },
        {
            title: "Numero",
            render: (rowData) => {
                return <span>{rowData.number}</span>;
            },
            field: "number",
            searchable: true,
        },
        {
            title: "Serie",
            render: (rowData) => {
                return <span>{rowData.serial}</span>;
            },
            field: "serial",
            searchable: true,
        },
        {
            title: "Fecha Ingreso",
            render: (rowData) => {
                return <span>{formatDateWithoutTimeZone(new Date(rowData.date), 'America/Bogota')}</span>;
            },
            field: "date",
            searchable: false,
        },
        {
            title: "Fecha Caducidad",
            render: (rowData) => {
                return <span>{formatDateWithoutTimeZone(new Date(rowData.dateExp), 'America/Bogota')}</span>;
            },
            field: "dateExp",
            searchable: false,
        },        
        {
            title: "Ubicación",
            render: (rowData) => {
                if (rowData.locations && rowData.locations.length > 0) {
                    return (
                        <span>
                            <CustomTooltip data={rowData.locations} />
                        </span>
                    );
                } else {
                    return <span> </span>;
                }
            },
            field: "locations",
        },
        {
            title: "Disponible",
            render: (rowData) => {
                return <span>{`${rowData.available}`}</span>;
            },
            field: "available",
            searchable: false,
        },
        {
            title: "Cantidad",
            render: (rowData) => {
                return <span>{`${rowData.quantity}`}</span>;
            },
            field: "quantity",
            searchable: true,
        },
    ];

    return (
        <Modal 
            show={show} 
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            size="xl"
        >
            <Modal.Header closeButton>
                <div className="d-flex justify-content-between align-items-justify w-25">
                    <Modal.Title>Inventario Por Lotes</Modal.Title>
                    {/* Botón para crear un nuevo lote */}
                    {/* <button
                        title='Crear Nuevo Lote'
                        className="btn btn-success btn-sm btn-circle ml-auto"
                        type="button"                        
                        onClick={handleShow}>
                        <i className='fas fa-plus fa-sm'></i>
                    </button> */}
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="card shadow mb-4">
                    {showLotsGrind && (
                        <>
                            <div className="card-header py-3">
                                <h6 className="m-0 font-weight-bold text-primary">
                                    Lotes
                                </h6>
                            </div>
                            <div className="card-body">
                                <form onSubmit={handleSubmit(performSearch)}>
                                    <div className="d-flex">
                                        <div className='form-group w-25 mr-2'>
                                            <Controller
                                                control={control}
                                                name="expired"
                                                render={({ field }) => (
                                                    <>
                                                        <label htmlFor="expired">Caducados</label>
                                                        <input type='checkbox' {...field}
                                                            id="expired"
                                                            checked={expiredChecked}
                                                            style={{
                                                            marginRight: "10px",
                                                            marginLeft: "10px",
                                                            marginTop: "10px",
                                                            }}
                                                            onChange={(e) => performSearch(field, e.target.checked, 'expired')}
                                                        />      
                                                    </>
                                                )} />
                                        </div>
                                        <div className='form-group w-25 mr-2'>
                                            <Controller
                                                control={control}
                                                name="spent"
                                                render={({ field }) => (
                                                    <>
                                                        <label htmlFor="spent">Agotados</label>
                                                        <input type='checkbox' {...field}
                                                            id="spent"
                                                            checked={spentChecked}
                                                            style={{
                                                            marginRight: "10px",
                                                            marginLeft: "10px",
                                                            marginTop: "10px",
                                                            }}
                                                            onChange={(e) => performSearch(field, e.target.checked, 'spent')}
                                                        />
                                                    </>
                                                )} />
                                        </div>
                                    </div>
                                </form>
                                <Grid
                                    cols={columns}
                                    data={combinedData && combinedData.items ? combinedData.items : []}
                                    page={combinedData && combinedData.page ? Number(combinedData.page) : 1}
                                    pages={combinedData && combinedData.totalPages ? Number(combinedData.totalPages) : 0}
                                    total={combinedData && combinedData.total ? combinedData.total : 0}
                                    offset={offset}
                                    onChangePage={(page) => reduxGetLots({
                                        idProduct: lotsInfo.idProduct,
                                        page: page,
                                        offset: offset,
                                        search: search,
                                        spent: spentChecked?.value ?? null,
                                        expired: expiredChecked?.value ?? null,
                                    })}
                                    onChangeRange={(value) => {
                                        setOffset(value);
                                        reduxGetLots({
                                            idProduct: lotsInfo.idProduct,
                                            page: 1,
                                            offset: value,
                                            search: search,
                                            spent: spentChecked?.value ?? null,
                                            expired: expiredChecked?.value ?? null,
                                        });
                                    }}
                                    defaultValue={search}
                                    onChangeSearch={(value) => {
                                        setSearch(value);
                                        reduxGetLots({
                                            idProduct: lotsInfo.idProduct,
                                            page: 1,
                                            offset: offset,
                                            search: value,
                                            spent: spentChecked?.value ?? null,
                                            expired: expiredChecked?.value ?? null,
                                        });
                                    }}
                                />
                            </div>
                        </>
                    )}
                    {showLots && (
                        <div className="card-body">
                            <div className="card-header py-3 d-flex justify-content-between align-items-center">
                                <h6 className="m-0 font-weight-bold text-primary">
                                    Crear nuevo lote
                                </h6>
                                <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 250, hide: 400 }}l
                                    overlay={<Tooltip>Cancelar Creación</Tooltip>}
                                >
                                    <Button variant="secondary" style={{ padding: '0.25rem 0.5rem', fontSize: '0.875rem' }} onClick={handleCloseCreate}>x</Button>
                                </OverlayTrigger>
                            </div>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="row">
                                <div className="form-group col-md-3">
                                        <label htmlFor="number" className="form-label">Numero *</label>
                                        <Controller
                                            control={control}
                                            name="number"
                                            rules={{
                                                required: 'Este Campo es requerido',
                                                maxLength: {
                                                    value: 20,
                                                    message: 'Máximo 20 caracteres permitidos'
                                                }
                                            }}
                                            render={({ field }) => (
                                                <>
                                                    <input type='text' {...field} className='form-control'/>
                                                    {errors.number && (
                                                        <span className='text-danger'>{errors.number.message}</span>
                                                    )}
                                                </>
                                            )}
                                        />
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label htmlFor="serial" className="form-label">Serial *</label>
                                        <Controller
                                            control={control}
                                            name="serial"
                                            rules={{
                                                required: 'Este Campo es requerido',
                                                maxLength: {
                                                    value: 20,
                                                    message: 'Máximo 20 caracteres permitidos'
                                                }
                                            }}
                                            render={({ field }) => (
                                                <>
                                                    <input type='text' {...field} className='form-control'/>
                                                    {errors.serial && (
                                                        <span className='text-danger'>{errors.serial.message}</span>
                                                    )}
                                                </>
                                            )}
                                        />
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label htmlFor="dateExp" className="form-label">Fecha Expiración *</label>
                                        <Controller
                                            control={control}
                                            name="dateExp"
                                            rules={{ required: 'Este Campo es requerido' }}
                                            render={({ field }) => (
                                                <>
                                                    <DatePicker
                                                        placeholderText='dd/mm/yyyy'
                                                        className='form-control'
                                                        onChange={(date) => field.onChange(date)}
                                                        selected={field.value}
                                                        dateFormat='dd/MM/yyyy'
                                                        minDate={new Date()}
                                                        showYearDropdown
                                                        showMonthDropdown
                                                        scrollableMonthYearDropdown
                                                    />
                                                    {errors.dateExp && (
                                                        <span className='text-danger'>{errors.dateExp.message}</span>
                                                    )}
                                                </>
                                            )}
                                        />
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label htmlFor="quantity" className="form-label">Cantidad *</label>
                                        <Controller
                                            control={control}
                                            name="quantity"
                                            rules={{
                                                required: 'Este Campo es requerido',
                                                min: {
                                                    value: 0,
                                                    message: 'La cantidad no puede ser negativa'
                                                },
                                                pattern: {
                                                    value: /^[0-9]+$/,
                                                    message: 'Sólo se permiten números'
                                                },
                                                maxLength: {
                                                    value: 20,
                                                    message: 'Máximo 20 caracteres permitidos'
                                                }
                                            }}
                                            render={({ field }) => (
                                                <>
                                                    <input type='number' {...field} className='form-control'/>
                                                    {errors.quantity && (
                                                        <span className='text-danger'>{errors.quantity.message}</span>
                                                    )}
                                                </>
                                            )}
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                    )}
                </div>
                
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>Cerrar</Button>
                {showLots && (
                    <Button variant="primary" type="submit" form="post-form" onClick={handleSubmit(onSubmit)}>
                        Guardar
                    </Button>
                )}                    
            </Modal.Footer>
        </Modal>
    );
};

const mapStateToProps = (state) => ({
    lots: state.warehouseState.lots,
    lot: state.warehouseState.lot,
    rowEdited: state.warehouseState.rowEdited,
    productLocations: state.warehouseState.productLocations,
    successfulCreateLots: state.warehouseState.successfulCreateLots,
    errorCreateLots: state.warehouseState.errorCreateLots,
});

const mapDispatchToProps = (dispatch) => ({
    reduxGetLots: (payload) => dispatch({ type: "GET_LOTS_REQUEST", value: payload }),
    reduxCreateLot: (payload) => dispatch({ type: "CREATE_LOTS_REQUEST", value: payload }),
    reduxGetWarehouses: (payload) => dispatch({ type: "FETCH_WAREHOUSES_REQUEST", value: payload }),
    reduxGetWarehouseById: (payload) => dispatch({ type: "READ_WAREHOUSE_REQUEST", value: payload }),
    reduxGetProductLocation: (payload) => dispatch({ type: "GET_PRODUCTLOCATION_REQUEST", value: payload }),
    reduxGetWarehouseInventoryByShipper: (payload) => dispatch({ type: "GET_WAREHOUSE_INVENTORY_BY_SHIPPER_REQUEST", value: payload }),
    reduxResetLots: () => dispatch({type: "RESET_LOTS_FORM"},)
});

export default connect(mapStateToProps, mapDispatchToProps)(InventoryLots);