import { useEffect, useState, useMemo, useRef } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { Button, OverlayTrigger, Tooltip, Form } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import Swal from "sweetalert2";
import moment from 'moment';

import { buttonIcon, buttonTypes } from "../../../../../utils/buttonsUtil";

// Componentes
import Sidebar from "../../../../../components/Navigation/Sidebar";
import Topbar from "../../../../../components/Navigation/Topbar";
import Header from "../../../../../components/Page/header";
import Grid from "../../../../../components/Grid";
import GuideScanner from "../../../../../components/Inputs/Scanner/GuideScanner";


const ReturnsEntry = ({
    reduxGetWMSOrderForReturn,
    successfulFetchWMSOrderForReturn,
    errorsFetchWMSOrderForReturn,
    reduxResetWMSOrderForReturn,
    wmsOrderForReturn,
    reduxCreateWMSReturn,
    successfulCreateWMSReturn,
    errorsCreateWMSReturn,
    createWMSReturnResult,
    reduxDownloadReturnCertificate,
    successfulDownloadReturnCertificate,
    errorsDownloadReturnCertificate,
    reduxResetAll,
}) => {
    const history = useHistory();
    const [scannedOrders, setScannedOrders] = useState([]);
    
    const {
        control,
        formState: { errors },
        setValue,
        getValues,
        setError,
        reset,
        handleSubmit
    } = useForm({
        defaultValues: {
            trackingOrReturn: '',
        }
    });

    const trackingOrReturnRef = useRef(null);

    useEffect(() => {
        const cleanupForm = () => {
            reset({
                trackingOrReturn: ''
            });
            setScannedOrders([]);
            reduxResetWMSOrderForReturn();
            setError('trackingOrReturn', null);
        };

        cleanupForm();

        if (trackingOrReturnRef.current) {
            trackingOrReturnRef.current.focus();
        }

        return () => cleanupForm();
    }, []); 

    useEffect(() => {
        if (successfulFetchWMSOrderForReturn === true) {
            const scannedGuide = getValues('trackingOrReturn');
            
            if (!wmsOrderForReturn) {
                Swal.fire({
                    title: 'Error',
                    text: `La guía ${scannedGuide} no existe en el sistema`,
                    icon: 'error',
                    confirmButtonText: 'Aceptar'
                });
                setValue('trackingOrReturn', '');
                trackingOrReturnRef.current.focus();
                return;
            }
            
            setScannedOrders(prevOrders => [...prevOrders, {
                trackingId: scannedGuide,
                product: wmsOrderForReturn.address.product,
                numOrder: wmsOrderForReturn.numOrder,
                idOperator: wmsOrderForReturn.address.idOperator
            }]);
            
            Swal.fire({
                title: '¡Éxito!',
                text: `Guía ${scannedGuide} escaneada correctamente`,
                icon: 'success',
                timer: 2000,
                showConfirmButton: false
            });

            setValue('trackingOrReturn', '');
            trackingOrReturnRef.current.focus();
            return;
        }

        if (errorsFetchWMSOrderForReturn === true) {
            const invalidGuide = getValues('trackingOrReturn');
            
            // Mostrar mensaje de error con SweetAlert2
            Swal.fire({
                title: 'Error',
                text: getErrorMessage(invalidGuide),
                icon: 'error',
                confirmButtonText: 'Aceptar'
            });

            setValue('trackingOrReturn', '');
            trackingOrReturnRef.current.focus();
            return;
        }
    }, [successfulFetchWMSOrderForReturn, errorsFetchWMSOrderForReturn]);

    useEffect(() => {
        if (successfulCreateWMSReturn) {
            if (createWMSReturnResult?.item?.idReturn) {
                try {
                    reduxDownloadReturnCertificate(createWMSReturnResult.item.idReturn);
                } catch (error) {
                    console.error('Error al iniciar la descarga:', error);
                    Swal.fire({
                        title: 'Error',
                        text: 'No se pudo descargar el certificado. Por favor, inténtelo más tarde.',
                        icon: 'error',
                        confirmButtonText: 'Aceptar',
                    });
                }
            }

            // Mostrar mensaje de éxito después de iniciar la descarga
            Swal.fire({
                title: 'Éxito',
                text: createWMSReturnResult?.message,
                icon: 'success',
                confirmButtonText: 'Aceptar',
            }).then(() => {
                setScannedOrders([]);
            });
        }

        if (errorsCreateWMSReturn) {
            const errorMessage = createWMSReturnResult?.message || 
                               createWMSReturnResult?.data?.message || 
                               'Ha ocurrido un error al crear la devolución';
            
            Swal.fire({
                title: 'Error',
                text: errorMessage,
                icon: 'error',
                confirmButtonText: 'Aceptar',
            });
        }
    }, [successfulCreateWMSReturn, errorsCreateWMSReturn, createWMSReturnResult]);
      
    useEffect(() => {
        const handleBeforeUnload = () => {
            reset({
                trackingOrReturn: ''
            });
            setScannedOrders([]);
        };

        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => window.removeEventListener('beforeunload', handleBeforeUnload);
    }, [reset]);

    useEffect(() => {
        if (successfulCreateWMSReturn && successfulDownloadReturnCertificate) {
            Swal.fire({
                title: 'Éxito',
                text: createWMSReturnResult?.message,
                icon: 'success',
                confirmButtonText: 'Aceptar',
            }).then(() => {
                // Limpiar estados
                setValue('trackingOrReturn', '');
                setScannedOrders([]);
                reduxResetAll();
                
                // Enfocar el campo de escaneo
                if (trackingOrReturnRef.current) {
                    trackingOrReturnRef.current.focus();
                }
            });
        }
    }, [successfulCreateWMSReturn, successfulDownloadReturnCertificate]);

    useEffect(() => {
        return () => {
            setValue('trackingOrReturn', '');
            setScannedOrders([]);
            reduxResetAll();
        };
    }, []);

    useEffect(() => {
        if (successfulCreateWMSReturn && successfulDownloadReturnCertificate) {
            Swal.fire({
                title: 'Éxito',
                text: createWMSReturnResult?.message,
                icon: 'success',
                confirmButtonText: 'Aceptar',
            }).then(() => {
                // Limpiar estados
                setValue('trackingOrReturn', '');
                setScannedOrders([]);
                reduxResetAll();
                
                // Enfocar el campo de escaneo
                if (trackingOrReturnRef.current) {
                    trackingOrReturnRef.current.focus();
                }
            });
        }
    }, [successfulCreateWMSReturn, successfulDownloadReturnCertificate]);

    useEffect(() => {
        return () => {
            setValue('trackingOrReturn', '');
            setScannedOrders([]);
            reduxResetAll();
        };
    }, []);

    const getErrorMessage = (guide) => {
        if (!guide || guide.trim() === '') {
            return 'La guía no puede estar vacía';
        }
        
        if (scannedOrders.includes(guide)) {
            return `La guía ${guide} ya ha sido escaneada`;
        }

        return `La guía ${guide} no es válida o no existe en el sistema`;
    };

    const searchOrder = (data) => {
        const guide = data.trackingOrReturn?.trim() || '';

        if (!guide) {
            reset({
                trackingOrReturn: ''
            });
            if (trackingOrReturnRef.current) {
                trackingOrReturnRef.current.focus();
            }
            return;
        }

        // Validar si la guía ya fue escaneada
        if (scannedOrders.includes(guide)) {
            Swal.fire({
                title: 'Advertencia',
                text: `La guía ${guide} ya ha sido escaneada`,
                icon: 'warning',
                confirmButtonText: 'Aceptar'
            });
            setValue('trackingOrReturn', '');
            trackingOrReturnRef.current.focus();
            return;
        }

        try {
            reduxGetWMSOrderForReturn({
                trackingOrReturn: guide
            });
        } catch (error) {
            Swal.fire({
                title: 'Error',
                text: 'Ha ocurrido un error al procesar la guía',
                icon: 'error',
                confirmButtonText: 'Aceptar'
            });
            console.error('Error al procesar la guía:', error);
        }
    };

    const handleCreateReturn = () => {
        // Validar si hay órdenes escaneadas
        if (scannedOrders.length === 0) {
            Swal.fire({
                title: 'Advertencia',
                text: 'Debe escanear al menos una guía para procesar la devolución',
                icon: 'warning',
                confirmButtonText: 'Aceptar'
            });
            return;
        }

        const returnDetail = scannedOrders.map(order => ({
            numOrder: order.numOrder,
        }));

        const payload = {
            idOperator: scannedOrders.length > 0 ? scannedOrders[0].idOperator : null,
            returnDetail,
        };

        console.log('Payload for reduxCreateWMSReturn:', payload);

        reduxCreateWMSReturn(payload);
    };

    const actionButtons = useMemo(() => [
        {
            onClick: () => history.push("/wms/pending-returns"),
            description: "Regresar a Ingresos",
            buttonType: buttonTypes.Primary,
            buttonIcon: buttonIcon.Arrow_LeftReply,
        }
    ], [history]);

    const columns = [
        {
            title: "Guía",
            field: "trackingId",
            searchable: true,
            render: (rowData) => <span>{rowData.trackingId}</span>
        },
        {
            title: "Producto",
            field: "product",
            searchable: true,
            render: (rowData) => <span>{rowData.product || 'N/A'}</span>
        },
        {
            title: "Acciones",
            render: (rowData) => {
                return (
                    <button
                        title='Eliminar'
                        className="btn btn-danger btn-sm btn-circle"
                        type="button"
                        onClick={() => handleRemoveRow(rowData)}
                    >
                        <i className="fas fa-trash fa-xs"></i>
                    </button>
                );
            }
        }
    ];

    const handleRemoveRow = (rowData) => {
        Swal.fire({
            title: '¿Estás seguro?',
            text: `¿Deseas eliminar la guía ${rowData.trackingId}?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
                setScannedOrders(prevOrders => 
                    prevOrders.filter(order => order.trackingId !== rowData.trackingId)
                );
                Swal.fire(
                    '¡Eliminado!',
                    `La guía ${rowData.trackingId} ha sido eliminada.`,
                    'success'
                );
            }
        });
    };

    return (
        <div id="wrapper">
            <Sidebar />
            <div id="content-wrapper" className="d-flex flex-column">
                <div id="content">
                    <Topbar />
                    <div className="container-fluid">
                        <Header 
                            title="Ingreso de Devoluciones"
                            actionButtons={actionButtons} 
                        />
                        
                        <div className="card shadow">
                            <div className="card-body p-0">
                                {/* Sección Superior - Escáner */}
                                <div className="p-4 border-bottom">
                                    <form onSubmit={handleSubmit(searchOrder)}>
                                        <div className="row align-items-end">
                                            <div className="col-12 col-md-6">
                                                <h6 className="font-weight-bold text-primary mb-3">
                                                    Escanear Devolución
                                                </h6>
                                                <div className="form-group mb-0">
                                                    <Controller
                                                        control={control}
                                                        name="trackingOrReturn"
                                                        rules={{
                                                            required: "Este campo es requerido"
                                                        }}
                                                        render={({ field }) => (
                                                            <div className="input-group">
                                                                <GuideScanner
                                                                    {...field}
                                                                    ref={trackingOrReturnRef}
                                                                    className="form-control form-control-user"
                                                                    placeholder="Escanea o ingresa la guía..."
                                                                    onScan={(value) => setValue('trackingOrReturn', value)}
                                                                />
                                                                {errors.trackingOrReturn && (
                                                                    <span className="error-message text-danger">
                                                                        {errors.trackingOrReturn.message}
                                                                    </span>
                                                                )}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                                {/* Sección Inferior - Listado */}
                                <div className="p-4">
                                    <h6 className="font-weight-bold text-primary mb-3">
                                        Listado de Devoluciones Escaneadas
                                    </h6>
                                    <div style={{ maxHeight: 'calc(100vh - 400px)', overflow: 'auto' }}>
                                        <Grid
                                            cols={columns}
                                            data={scannedOrders}
                                            showFilters={false}
                                            enableFixedColumns={true}
                                            stickyHeader={true}
                                            showRange={false}
                                            style={{ 
                                                maxHeight: '100%',
                                                overflow: 'auto'
                                            }}
                                        />
                                    </div>
                                    {/* Confirm Button */}
                                    <div className="mt-3 text-center">
                                        <Button 
                                            variant="primary" 
                                            onClick={handleCreateReturn}
                                        >
                                            Confirmar
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
      successfulFetchWMSOrderForReturn: state.wmsState.successfulFetchWMSOrderForReturn,
      errorsFetchWMSOrderForReturn: state.wmsState.errorsFetchWMSOrderForReturn,
      wmsOrderForReturn: state.wmsState.wmsOrderForReturn,
      successfulCreateWMSReturn: state.wmsState.successfulCreateWMSReturn,
      errorsCreateWMSReturn: state.wmsState.errorsCreateWMSReturn,
      createWMSReturnResult: state.wmsState.createWMSReturnResult, // Agregamos este
      successfulDownloadReturnCertificate: state.wmsState.successfulDownloadReturnCertificate,
      errorsDownloadReturnCertificate: state.wmsState.errorsDownloadReturnCertificate,
    };
  };

const mapDispatchToProps = (dispatch) => {
    return {
        reduxGetWMSOrderForReturn: (payload) =>
            dispatch({
                type: "FETCH_WMS_ORDER_FOR_RETURN_REQUEST",
                value: payload,
            }),
        reduxResetWMSOrderForReturn: () =>
            dispatch({
                type: "RESET_WMS_ORDER_FOR_RETURN"
            }),
        reduxCreateWMSReturn: (payload) =>
            dispatch({
                type: "CREATE_WMS_RETURN_REQUEST",
                value: payload,
            }),
        reduxDownloadReturnCertificate: (idReturn) =>
            dispatch({
                type: "DOWNLOAD_RETURN_CERTIFICATE_REQUEST",
                value: idReturn,
            }),
        reduxResetAll: () => {
            dispatch({ type: "RESET_WMS_ORDER_FOR_RETURN" });
            dispatch({ type: "RESET_CREATE_WMS_RETURN" });
            dispatch({ type: "RESET_DOWNLOAD_RETURN_CERTIFICATE" });
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReturnsEntry);