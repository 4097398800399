import { useEffect, useState } from "react";

import { connect } from "react-redux";
import { Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { isNullOrUndefined, isValidCSV } from '../../../../../utils/functionsUtils';
import { MimeTypesEnum } from "../../../../../utils/enumsUtil";
import { DateTime, IANAZone } from "luxon";


const WMSMassiveDispatchModal = ({
    show,
    handleClose,
    detailBulkUploadOrdersWMS,
    bulkUploadOrdersWMSRequest
}) => {

    const {
        handleSubmit: uploadModalSumbit,
        control: uploadModalControl,
        formState: { errors: uploadModalErrors },
        watch,
        setValue,
        reset,
    } = useForm({
        defaultValues: {
            fileWithOrders: null,
        }
    });

    const resetFileSelection = () => {
        setValue('fileWithOrders', '');
    };

    const renderHelpTooltip = (title) => {
        return (
            <Tooltip id="button-tooltip">
                <h6>El Excel debe tener formato CSV y no debe tener encabezados.</h6>
            </Tooltip>
        );
    };

    const uploadMassivePreparation = (data) => {
        Swal.fire({
            html: `¿Está seguro de actualizar el estado de estas órdenes?`,
            icon: 'question',
            showConfirmButton: true,
            confirmButtonText: "OK",
            showCancelButton: true,
            cancelButtonText: "Cancelar",
        }).then(result => {
            if (!result.isConfirmed) return;

            const reader = new FileReader();
            reader.readAsText(data.fileWithOrders);
            reader.onload = () => {
                if (!isValidCSV(reader.result, 1, 1)) {
                    resetFileSelection();
                    return;
                }
                bulkUploadOrdersWMSRequest(data.fileWithOrders);
            }
            reader.onerror = (error) => {
                resetFileSelection();
            }
        });
    }

    const fileWatcher = watch('fileWithOrders', null);

    useEffect(() => {
        reset();
    }, []);

    useEffect(() => {
        if (detailBulkUploadOrdersWMS) {
            if (Array.isArray(detailBulkUploadOrdersWMS.results) && detailBulkUploadOrdersWMS.results.some(result => result.status === 'error')) {
                const errorOrders = detailBulkUploadOrdersWMS.results.filter(result => result.status === 'error');
                const csvData = errorOrders.map(order => `${order.trackingId.trim()};${order.message}`).join('\n');
                const bom = '\uFEFF';
                const csvContent = bom + csvData;
                const fileName = DateTime.now().setZone(new IANAZone('America/Bogota')).toFormat('yyyy-MM-dd') + '-detailBulkUploadOrdersWMSErrors.csv';
                const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
                const link = document.createElement('a');
                if (link.download !== undefined) {
                    const url = URL.createObjectURL(blob);
                    link.setAttribute('href', url);
                    link.setAttribute('download', fileName);
                    link.style.visibility = 'hidden';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
                Swal.fire(
                    'Proceso finalizado con errores',
                    `${detailBulkUploadOrdersWMS.message}. Se ha generado un archivo CSV con los detalles de los errores.`,
                    'error'
                );
                handleClose();

                resetFileSelection();
                reset();
            } else {
                Swal.fire(
                    'Proceso finalizado con éxito',
                    `${detailBulkUploadOrdersWMS.message}`,
                    'success'
                );
                handleClose();

                resetFileSelection();
                reset();
            }

        }
    }, [detailBulkUploadOrdersWMS]);

    return (
        <>
            <Modal
                show={show}
                onHide={() => { handleClose(); resetFileSelection(); }}
                onEscapeKeyDown={() => { handleClose(); resetFileSelection(); }}
                backdrop="static"
                keyboard={false}
                size="lg"
            >
                <form onSubmit={uploadModalSumbit(uploadMassivePreparation)}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="mx-2">
                                    <OverlayTrigger
                                        placement='bottom'
                                        overlay={renderHelpTooltip("Formato de Cargue")}>
                                        <i className="fas fa-info-circle fa-sm"></i>
                                    </OverlayTrigger>
                                </div>
                                <div>Cargar Archivo de Ordenes a Despachar</div>
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="form-group col-md-12">
                                <label htmlFor="fileWithOrders" className="form-label">
                                    Archivo *
                                </label>
                                <Controller
                                    control={uploadModalControl}
                                    name="fileWithOrders"
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'Debe seleccionar un archivo'
                                        },
                                        validate: (value) => {
                                            return !isNullOrUndefined(MimeTypesEnum.CSV.find(e => e === value.type)) || "El archivo debe ser formato CSV";
                                        },
                                    }}
                                    render={({ field }) => (
                                        <div className="custom-file">
                                            <input
                                                type='file'
                                                accept=".csv"
                                                onChange={(e) => {
                                                    const file = e.target.files[0];
                                                    setValue('fileWithOrders', file);
                                                }}
                                                onClick={(e) => e.target.value = null}
                                                className={`custom-file-input ${uploadModalErrors.fileWithOrders && 'is-invalid'}`}
                                            />
                                            <label
                                                className='custom-file-label'
                                                htmlFor='validatedCustomFile'
                                                onClick={() => document.getElementById('validatedCustomFile').click()}
                                            >
                                                {fileWatcher ? fileWatcher.name : 'Seleccionar archivo...'}
                                            </label>
                                            {
                                                uploadModalErrors.fileWithOrders &&
                                                <span className="error-message">{uploadModalErrors.fileWithOrders.message}</span>
                                            }
                                        </div>
                                    )}
                                />
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => { handleClose(); resetFileSelection(); }}>
                            Cancelar
                        </Button>
                        <Button variant="primary" type="submit">
                            Guardar
                        </Button>
                    </Modal.Footer>
                </form >
            </Modal>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        detailBulkUploadOrdersWMS: state.warehouseState.detailBulkUploadOrdersWMS,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        bulkUploadOrdersWMSRequest: (payload) =>
            dispatch({
                type: "BULK_UPLOAD_ORDERS_WMS_REQUEST",
                value: payload,
            }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(WMSMassiveDispatchModal);