import { useState, useEffect,forwardRef } from "react";
import { Button, Modal } from "react-bootstrap";
import { useHistory, useParams } from "react-router";
import { connect } from "react-redux";
import { buttonIcon, buttonTypes } from "../../../../../utils/buttonsUtil";
import Swal from "sweetalert2";
import { FaBarcode, FaCheck, FaTimes } from 'react-icons/fa';

import Sidebar from "../../../../../components/Navigation/Sidebar";
import Topbar from "../../../../../components/Navigation/Topbar";
import Header from "../../../../../components/Page/header";
import Grid from "../../../../../components/Grid";
import GuideScanner from "../../../../../components/Inputs/Scanner/GuideScanner";

import ReturnsModal from '../../../../../components/Modal/WMS/Incomings/Returns';

const ReturnsProcess = ({
    reduxProcessReturn,
    reduxProcessReturnOrder,
    successfulProcessReturn,
    errorsProcessReturn,
    processReturnResult,
    successfulProcessReturnOrder,
    errorsProcessReturnOrder,
    processReturnOrderResult,
    reduxConfirmReturn,
    successfulConfirmReturn,
    errorsConfirmReturn,
    confirmReturnResult,
    reduxResetStates,
}) => {

    const history = useHistory();
    const { idReturn } = useParams();
    const [isProcessing, setIsProcessing] = useState(false);
    const [returnProducts, setReturnProducts] = useState([]);
    const [processedGuides, setProcessedGuides] = useState(new Set());
    const [failedGuides, setFailedGuides] = useState([]);
    const [currentGuideIndex, setCurrentGuideIndex] = useState(0);
    const [isProcessingGuides, setIsProcessingGuides] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [scannedGuide, setScannedGuide] = useState('');
    const [scannedGuides, setScannedGuides] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [comments, setComments] = useState('');
    const [filesWatcher, setFilesWatcher] = useState([]);

    useEffect(() => {
        reduxProcessReturn(idReturn);
        return () => {
            setReturnProducts([]);
        };
    }, []);

    useEffect(() => {
        if (successfulProcessReturn && processReturnResult?.returnDetail) {
            // Limpiar estados previos
            setProcessedGuides(new Set());
            setFailedGuides([]);
            setReturnProducts([]);
            setCurrentGuideIndex(0);
            setIsProcessingGuides(true);
        }
    }, [successfulProcessReturn, processReturnResult]);

    useEffect(() => {
        if (isProcessingGuides && processReturnResult?.returnDetail) {
            const guides = processReturnResult.returnDetail;
            
            if (currentGuideIndex < guides.length) {
                const currentGuide = guides[currentGuideIndex];
                
                // Procesar la guía actual
                reduxProcessReturnOrder({
                    idReturn: processReturnResult.idReturn,
                    trackingOrReturn: currentGuide.trackingId || currentGuide.returnTrackingId
                });
            } else {
                setIsProcessingGuides(false);
            }
        }
    }, [isProcessingGuides, currentGuideIndex, processReturnResult]);

    useEffect(() => {
        if (successfulProcessReturnOrder && processReturnOrderResult?.returnDetail?.[0]) {
            const returnDetail = processReturnOrderResult.returnDetail[0];
            const tracking = returnDetail.order?.address?.trackingId;
            
            if (tracking && returnDetail.order?.orderDetails) {
                const products = returnDetail.order.orderDetails.map(detail => ({
                    tracking: tracking,
                    numOrder: returnDetail.order.numOrder,
                    product: detail.product.description,
                    quantity: detail.quantity,
                    idProduct: detail.product.idProduct,
                    idAddress: returnDetail.order?.address?.idAddress
                }));
                
                setReturnProducts(prev => {
                    const newProducts = products.filter(newProduct => 
                        !prev.some(existingProduct => 
                            existingProduct.tracking === newProduct.tracking &&
                            existingProduct.idProduct === newProduct.idProduct
                        )
                    );
                    return [...prev, ...newProducts];
                });

                // Marcar la guía como procesada
                const currentGuide = processReturnResult?.returnDetail[currentGuideIndex];
                if (currentGuide) {
                    setProcessedGuides(prev => new Set([...prev, currentGuide.trackingId]));
                }
            }
            
            // Avanzar a la siguiente guía
            setCurrentGuideIndex(prev => prev + 1);
        }
    }, [successfulProcessReturnOrder, processReturnOrderResult]);

    useEffect(() => {
        if (errorsProcessReturnOrder && processReturnResult?.returnDetail) {
            const currentGuide = processReturnResult.returnDetail[currentGuideIndex];
            
            if (currentGuide) {
                setFailedGuides(prev => [...prev, {
                    tracking: currentGuide.trackingId,
                    numOrder: currentGuide.numOrder,
                    error: "No se encontró la orden"
                }]);
            }
            
            // Avanzar a la siguiente guía incluso si hubo error
            setCurrentGuideIndex(prev => prev + 1);
        }
    }, [errorsProcessReturnOrder]);

    useEffect(() => {
        if (errorsConfirmReturn) {
            setIsProcessing(false);
            Swal.fire({
                title: "Error",
                text: "Hubo un error al procesar la devolución. Por favor, intente nuevamente.",
                icon: "error",
                confirmButtonText: "Aceptar"
            });
        }
        return () => {
            setIsProcessing(false);
        };
    }, [errorsConfirmReturn]);

    useEffect(() => {
        if (successfulConfirmReturn) {
            setIsProcessing(false);
            Swal.fire({
                title: "¡Éxito!",
                text: "La devolución se ha procesado correctamente",
                icon: "success",
                confirmButtonText: "Aceptar"
            }).then((result) => {
                if (result.isConfirmed || result.isDismissed) {
                    history.push("/wms/pending-returns");
                }
            });
        }
        return () => {
            setIsProcessing(false);
        };
    }, [successfulConfirmReturn]);

    const handleProcess = () => {
        if (isProcessing) return; 

        Swal.fire({
            title: "¿Está seguro?",
            text: "¿Desea confirmar el procesamiento de esta(s) devolución(es)?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Confirmar",
            cancelButtonText: "Cancelar"
        }).then((result) => {
            if (result.isConfirmed) {
                setIsProcessing(true);
                
                try {
                    const groupedReturnDetails = returnProducts.reduce((acc, product, index) => {
                        const key = `${product.tracking}-${product.numOrder}-${product.idAddress}`;
                        if (!acc[key]) {
                            acc[key] = {
                                numOrder: product.numOrder,
                                idAddress: product.idAddress,
                                trackingId: product.tracking,
                                orderDetail: [],
                                return_guia_index: index
                            };
                        }
                        acc[key].orderDetail.push({
                            idProduct: product.idProduct,
                            available: product.quantity,
                            damaged: product.damaged || 0,
                            missing: product.missing || 0,
                            extra: product.extra || 0,
                            expired: product.expired || 0,
                            supplanted: product.supplanted || 0,
                            observations: product.observations ,
                        });
                        return acc;
                    }, {});

                    const returnData = {
                        idReturn: parseInt(idReturn),
                        returnDetail: Object.values(groupedReturnDetails).map(detail => ({
                            ...detail,
                            images: [],
                            orderDetail: detail.orderDetail.map(item => {
                                const totalQuantity = item.available + item.damaged + item.missing + item.extra + item.expired + item.supplanted;
                                return {
                                    idProduct: item.idProduct,
                                    available: (item.damaged === 0 && item.missing === 0 && item.extra === 0 && item.expired === 0 && item.supplanted === 0) ? totalQuantity : 0,
                                    damaged: item.damaged,
                                    missing: item.missing,
                                    extra: item.extra,
                                    expired: item.expired,
                                    supplanted: item.supplanted,
                                    observations: item.observations,
                                };
                            })
                        }))
                    };

                    const formData = new FormData();
                    formData.append('data', JSON.stringify(returnData));

                    // Agregar las imágenes al FormData
                    returnProducts.forEach(product => {
                        const productImages = product.evidences || [];
                        productImages.forEach((file, index) => {
                            const imageKey = `${idReturn}_${product.tracking}_${index}.png`;
                            formData.append(`images`, file, imageKey);
                        });
                    });

                    reduxConfirmReturn(formData);
                } catch (error) {
                    setIsProcessing(false); // Habilitar el botón si hay error al preparar los datos
                    Swal.fire({
                        title: "Error",
                        text: "Error al preparar los datos. Por favor, intente nuevamente.",
                        icon: "error",
                        confirmButtonText: "Aceptar"
                    });
                }
            }
        });
    };

    const handleScanGuide = (guideNumber) => {
        const upperCaseGuideNumber = guideNumber.toUpperCase();
        const guideExists = returnProducts.some(product => product.tracking === upperCaseGuideNumber);
        if (guideExists) {
            setScannedGuide(upperCaseGuideNumber);
            setScannedGuides([upperCaseGuideNumber]);
            setShowModal(true);
        } else {
            Swal.fire({
                title: "Error",
                text: "La guía escaneada no coincide con ninguna devolución disponible.",
                icon: "error",
                confirmButtonText: "Aceptar"
            });
        }
        setInputValue(''); 
    };

    const handleInputChange = (e) => {
        const value = e.target.value;
        setInputValue(value);
        handleScanGuide(value);
    };

    const onOpenModal = (isOpen) => {
        setShowModal(isOpen);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setScannedGuides([]);
        setScannedGuide('');
        setInputValue('');
        setComments('');
        setFilesWatcher([]);
    };

    const handleSave = (editedData) => {
        const updatedProducts = [...returnProducts];
        editedData.forEach(editedProduct => {
            const existingProductIndex = updatedProducts.findIndex(product => 
                product.tracking === editedProduct.tracking && product.product === editedProduct.product
            );

            if (existingProductIndex >= 0) {
                updatedProducts[existingProductIndex] = {
                    ...updatedProducts[existingProductIndex],
                    ...editedProduct,
                    evidences: editedProduct.evidences,
                    observations: editedProduct.comments
                };
            } else {
                updatedProducts.push({
                    ...editedProduct,
                    evidences: editedProduct.evidences,
                    observations: editedProduct.comments
                });
            }
        });

        // Actualiza el estado con los productos editados
        setReturnProducts(updatedProducts);

        // Limpiar los campos del modal después de guardar
        setScannedGuides([]);
        setScannedGuide('');
        setInputValue('');
        setComments('');
        setFilesWatcher([]);
    };

    const actionButtons = [
        {
            onClick: () => history.push("/wms/pending-returns"),
            description: "Regresar a Devoluciones",
            buttonType: buttonTypes.Primary,
            buttonIcon: buttonIcon.Arrow_LeftReply,
        },
        // Comentado el botón de escanear ya que no se está utilizando
        /*
        {
            onClick: () => setShowModal(true),
            description: "Escanear",
            buttonType: buttonTypes.Success,
            buttonIcon: buttonIcon.Barcode,
        }
        */
    ];

    const columns = [
        {
            title: "Guía",
            field: "tracking",
            searchable: false,
            render: (item) => <span>{item.tracking}</span>,
        },
        {
            title: "Producto",
            field: "product",
            searchable: false,
            render: (item) => <span>{item.product}</span>,
        },
        {
            title: "Cantidad",
            field: "quantity",
            searchable: false,
            render: (item) => <span>{item.quantity}</span>,
        },
        {
            title: "Disponible",
            field: "available",
            searchable: false,
            render: (item) => <span>{item.available}</span>,
        },
        {
            title: "Averiado",
            field: "damaged",
            searchable: false,
            render: (item) => <span>{item.damaged}</span>,
        },
        {
            title: "Faltante",
            field: "missing",
            searchable: false,
            render: (item) => <span>{item.missing}</span>,
        },
        {
            title: "Sobrante",
            field: "extra",
            searchable: false,
            render: (item) => <span>{item.extra}</span>,
        },
        {
            title: "Caducados",
            field: "expired",
            searchable: false,
            render: (item) => <span>{item.expired}</span>,
        },
        {
            title: "Suplantación",
            field: "supplanted",
            searchable: false,
            render: (item) => <span>{item.supplanted}</span>,
        },
    ];

    useEffect(() => {
        return () => {
            setIsProcessing(false);
            setReturnProducts([]);
            setScannedGuides([]);
            setScannedGuide('');
            reduxResetStates(); 
        };
    }, []);

    return (
        <div id="wrapper">
            <Sidebar />
            <div id="content-wrapper" className="d-flex flex-column">
                <div id="content">
                    <Topbar />
                    <div className="container-fluid">
                        <Header title="Procesar Devolución" actionButtons={actionButtons} />
                        
                        <div className="card shadow mb-4">
                            <div className="card-header py-3">
                                <h6 className="m-0 font-weight-bold text-primary">
                                    Escáner devoluciones
                                </h6>
                            </div>
                            <div className="card-body">
                                <div className="mb-3">
                                    <GuideScanner
                                        className="form-control"
                                        placeholder="Escanee la Guía"
                                        onScan={(e) => {
                                            handleInputChange({ target: { value: e } });
                                        }}
                                    />
                                </div>
                            </div>
                        </div>

                        <ReturnsModal 
                            returnProducts={returnProducts} 
                            idReturn={idReturn}
                            reduxConfirmReturn={reduxConfirmReturn}
                            show={showModal}
                            onHide={handleCloseModal}
                            scannedGuide={scannedGuide}
                            onOpenModal={onOpenModal}
                            scannedGuides={scannedGuides}
                            onSave={handleSave}
                        />
                        
                        <div className="card shadow mb-4">
                            <div className="card-header py-3">
                                <h6 className="m-0 font-weight-bold text-primary">
                                    Devolución #{idReturn}
                                </h6>
                            </div>
                            <div className="card-body">
                                {returnProducts.length > 0 && (
                                    <>
                                        <div className="table-responsive">
                                            <Grid
                                                cols={columns}
                                                data={returnProducts}
                                                showFilters={false}
                                                showRange={false}
                                            />
                                        </div>
                                        <div className="mt-3 text-center">
                                            <Button 
                                                variant="primary" 
                                                onClick={handleProcess}
                                                disabled={isProcessing}
                                            >
                                                {isProcessing ? 'Procesando...' : 'Confirmar'}
                                            </Button>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    successfulProcessReturn: state.wmsState.successfulProcessReturn,
    errorsProcessReturn: state.wmsState.errorsProcessReturn,
    processReturnResult: state.wmsState.processReturnResult,
    successfulProcessReturnOrder: state.wmsState.successfulProcessReturnOrder,
    errorsProcessReturnOrder: state.wmsState.errorsProcessReturnOrder,
    processReturnOrderResult: state.wmsState.processReturnOrderResult,
    successfulConfirmReturn: state.wmsState.successfulConfirmReturn,
    errorsConfirmReturn: state.wmsState.errorsConfirmReturn,
    confirmReturnResult: state.wmsState.confirmReturnResult,
});

const mapDispatchToProps = (dispatch) => ({
    reduxProcessReturn: (idReturn) =>
        dispatch({
            type: "PROCESS_WMS_RETURN_REQUEST",
            value: idReturn,
        }),
    reduxProcessReturnOrder: (payload) =>
        dispatch({
            type: "PROCESS_WMS_RETURN_ORDER_REQUEST",
            value: payload,
        }),
    reduxConfirmReturn: (payload) =>
        dispatch({
            type: "CONFIRM_WMS_RETURN_REQUEST",
            value: payload,
        }),
    reduxResetStates: () => {
        dispatch({ type: "RESET_PROCESS_RETURN" });
        dispatch({ type: "RESET_PROCESS_RETURN_ORDER" });
        dispatch({ type: "RESET_CONFIRM_RETURN" });
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(ReturnsProcess);