import { call, put, takeLatest } from "redux-saga/effects";
import request from "../../utils/request";

function* getShipperInventory(payload) {
  try {

    yield put({
      type: "READ_WAREHOUSE_SHIPPERINVENTORY_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/warehouse/inventory/shipperInventory?shipper=${payload.value.shipper ?? ''}&warehouses=${payload.value.warehouses ?? ''}&page=${payload.value.page ?? ''}&offset=${payload.value.offset ?? ''}&search=${payload.value.search ?? ''}`;

    const headers = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "READ_WAREHOUSE_SHIPPERINVENTORY_SUCCESS",
      value: response,
    });

  } catch (error) {
    yield put({
      type: "READ_WAREHOUSE_SHIPPERINVENTORY_ERROR",
    });
  } finally {
    yield put({
      type: "HIDE_LOADING",
    });
  }
}

function* getInventory(payload) {
  try {

    yield put({
      type: "READ_INVENTORY_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/warehouse/inventory?shipper=${payload.value.shipper ?? ''}&warehouses=${payload.value.warehouses ?? ''}&page=${payload.value.page ?? ''}&offset=${payload.value.offset ?? ''}&search=${payload.value.search ?? ''}`;

    const headers = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "READ_INVENTORY_SUCCESS",
      value: response,
    });

  } catch (error) {
    yield put({
      type: "READ_INVENTORY_ERROR",
    });
  } finally {
    yield put({
      type: "HIDE_LOADING",
    });
  }
}

function* downloadInventory(payload) {
  try {

    yield put({
      type: "DOWNLOAD_INVENTORY_REQUESTING",
    });

    const requestURL = `${process.env.REACT_APP_REPORT_URL}/api/v1/warehouse/inventory/downloadInventory`;

    const headers = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload.value),
    };
    const response = yield call(fetch, requestURL.trim(), headers);

    const blob = yield call([response, 'blob']);

    var fileName='Inventario_' + Date.now() + '.csv';

    // Obtener el nombre del archivo del header 'Content-Disposition'
    // const contentDisposition = response.headers.get('Content-Disposition');
    // console.log(response.headers);
    // if (contentDisposition && contentDisposition.includes('filename=')) {
      
    //   const fileNameMatch = contentDisposition.match(/filename="?(.+)"?/);
    //   if (fileNameMatch.length > 0) {
    //     fileName = fileNameMatch[0];
    //   }
    // }

    const url = window.URL.createObjectURL(blob);
    let a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    a.remove();

    yield put({
      type: "DOWNLOAD_INVENTORY_SUCCESS",
      payload: { url, fileName }
    });

  } catch (error) {
    console.log(error);
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed download",
        message: "Failed download inventory report",
      },
    });
    yield put({
      type: "DOWNLOAD_INVENTORY_ERROR",
    });
  } 
}

function* uploadInventoryMovement(payload) {
  try {
    yield put({
      type: "UPLOAD_SHIPPERINVENTORY_MOVEMENT_REQUESTING"
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/warehouse/inventory/uploadStockMovement`;

    const formData = new FormData();
    formData.append("file", payload.value.file);
    formData.append("idShipper", payload.value.idShipper);
    formData.append("idWarehouse", payload.value.idWarehouse);
    formData.append("movementType", payload.value.movementType);
    formData.append("movementSubtype", payload.value.movementSubtype);

    const headers = {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
      }),
      body: formData,
    };

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "success",
        title: "Successful save",
        message: "Successful save inventory",
      },
    });

    yield put({
      type: "UPLOAD_SHIPPERINVENTORY_MOVEMENT_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed save",
        message: "Failed save inventory",
      },
    });
    yield put({
      type: "UPLOAD_SHIPPERINVENTORY_MOVEMENT_ERROR",
    });
  } finally {
    yield put({
      type: "HIDE_LOADING",
    });
  }
}

function* fetchMovements(payload) {
  try {
    yield put({
      type: "READ_WAREHOUSE_INVENTORY_HISTORY_REQUESTING",
    });
    yield put({
      type: "SHOW_LOADING",
    });
    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/warehouse/inventory/stockMovement/${payload.value.id}`;
    const headers = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "READ_WAREHOUSE_INVENTORY_HISTORY_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "READ_WAREHOUSE_INVENTORY_HISTORY_ERROR",
    });
  } finally {
    yield put({
      type: "HIDE_LOADING",
    });
  }
}

function* downloadInventoryMovementsReport(payload) {
  try {
    yield put({
      type: "DOWNLOAD_INVENTORY_MOVEMENTS_REPORT_REQUESTING",
    });
    
    yield put({
      type: "SHOW_LOADING",
    });

    const fileName = payload.value.fileName;
    
    const headers = {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(payload.value),
    };

    let requestURL = `${process.env.REACT_APP_REPORT_URL}/api/v1/warehouse/inventory/downloadMovementsReport`;

    const response = yield call(fetch, requestURL.trim(), headers);
    const blob = yield call([response, 'blob']);

    const url = window.URL.createObjectURL(blob);
    let a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    a.remove();

    yield put({
      type: "DOWNLOAD_INVENTORY_MOVEMENTS_REPORT_SUCCESS",
      payload: { url, fileName }
    });
  } catch (error) {
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed download",
        message: "Failed download general report",
      },
    });
    yield put({
      type: "DOWNLOAD_INVENTORY_MOVEMENTS_REPORT_ERROR",
    });
  } finally {
    yield put({
      type: "HIDE_LOADING",
    });
  }
}

function* downloadIncsOutsReport(payload) {
  try {
    yield put({
      type: "DOWNLOAD_INCOMINGS_OUTGOINGS_REPORT_REQUESTING",
    });

    const fileName = payload.value.fileName;
    
    const headers = {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(payload.value),
    };

    let requestURL = `${process.env.REACT_APP_API_URL}/api/v1/warehouse/inventory/downloadIncsOutsReport`;

    const response = yield call(fetch, requestURL.trim(), headers);
    const blob = yield call([response, 'blob']);

    const url = window.URL.createObjectURL(blob);
    let a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    a.remove();

    yield put({
      type: "DOWNLOAD_INCOMINGS_OUTGOINGS_REPORT_SUCCESS",
      payload: { url, fileName }
    });
  } catch (error) {
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed download",
        message: "Failed download general report",
      },
    });
    yield put({
      type: "DOWNLOAD_INCOMINGS_OUTGOINGS_REPORT_ERROR",
    });
  }
}

function* downloadStockReport(payload) {
  try {
    yield put({
      type: "DOWNLOAD_STOCK_INVENTORY_REQUESTING",
    });

    const fileName = payload.value.fileName;
    
    const headers = {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(payload.value),
    };

    let requestURL = `${process.env.REACT_APP_API_URL}/api/v1/warehouse/inventory/downloadStockReport`;

    const response = yield call(fetch, requestURL.trim(), headers);
    const blob = yield call([response, 'blob']);

    const url = window.URL.createObjectURL(blob);
    let a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    a.remove();

    yield put({
      type: "DOWNLOAD_STOCK_INVENTORY_SUCCESS",
      payload: { url, fileName }
    });
  } catch (error) {
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed download",
        message: "Failed download general report",
      },
    });
    yield put({
      type: "DOWNLOAD_STOCK_INVENTORY__ERROR",
    });
  }
}

export function* watchInventory() {
  yield takeLatest("READ_WAREHOUSE_SHIPPERINVENTORY_REQUEST", getShipperInventory);
  yield takeLatest("READ_INVENTORY_REQUEST", getInventory);
  yield takeLatest("UPLOAD_SHIPPERINVENTORY_MOVEMENT_REQUEST", uploadInventoryMovement);
  yield takeLatest("READ_WAREHOUSE_INVENTORY_HISTORY_REQUEST", fetchMovements);
  yield takeLatest("DOWNLOAD_INVENTORY_MOVEMENTS_REPORT_REQUEST", downloadInventoryMovementsReport);
  yield takeLatest("DOWNLOAD_INCOMINGS_OUTGOINGS_REPORT_REQUEST", downloadIncsOutsReport);
  yield takeLatest("DOWNLOAD_STOCK_INVENTORY_REQUEST", downloadStockReport);
  yield takeLatest("DOWNLOAD_INVENTORY_REQUEST", downloadInventory);
}
